import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppConfig } from 'src/app/config/app.config';
import { AppDrawerComponent } from 'src/app/modules/shared/app-drawer/app-drawer.component';
import { UserService } from '../auth/user.service';
import { UserType } from '../forms/Forms';
import { OverlayService } from '../overlay/overlay.service';
import { UserSettingsService } from '../settings/user-settings.service';

export const PageNavigationTitle = {
  MAIN_TITLE: 'Job Brief', // Will be prefixed with the user type
  BACK: 'Back',
  CREW_FAVORITES: 'Crew Favorites',
  TECH_FAVORITES: 'Tech Favorites',
  SEARCH: 'Search',
  MY_FORMS: 'My Forms'
};

export const SAVING_TIMEOUT = 3000;

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private _back$ = new Subject<() => void>();
  public expanded$ = new Subject<boolean>();
  private _title: string;

  private _hashFragment$ = new BehaviorSubject<string>(null);

  loggedIn: boolean;
  url: string;
  loadingFormsPage: boolean;
  showBack: boolean;
  isSaving: boolean;
  redNavbar: boolean;
  printing: boolean;

  viewingFormFromSearch = false;

  userType: UserType;

  showEnvironments: Boolean;

  constructor(
    private router: Router,
    private userService: UserService,
    private overlayService: OverlayService,
    private activatedRoute: ActivatedRoute,
    private config: AppConfig,
    private userSettings: UserSettingsService
  ) {
    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationEnd) {
          this.userSettings.userType.subscribe(
            userType => {
              this.userType = userType;

              this.configureTitleAndNavbar(event?.url);
            }
          );
        }
      }
    );

    this.activatedRoute.fragment.subscribe(
      fragment => {
        const newFragments = fragment?.split(',')?.filter(f => f?.length > 0)?.length ?? 0;
        const prevFragments = this._hashFragment$.value?.split(',')?.filter(f => f?.length > 0)?.length ?? 0;
        if (newFragments < prevFragments) {
          // Triggers back navigation within form
          this._back$.next();
        }
        this._hashFragment$.next(fragment);
      }
    );

    this.activatedRoute.queryParamMap.subscribe(
      qP => {
        this.viewingFormFromSearch = qP.get('fromSearch') === 'true';
      }
    );
  }

  get crewFavoritesTitle() {
    // $_FORM_TYPE_ADJUSTMENT_$ -> Crew favorites title
    switch (this.userType) {
      case 'line':
      case 'non-craft':
      case 'transmission':
        return PageNavigationTitle.CREW_FAVORITES;
      case 'fleet':
        return PageNavigationTitle.TECH_FAVORITES;
    }
  }

  configureTitleAndNavbar(url?: string) {
    this.setMainTitle();

    this.loggedIn = !!this.userService.azureAccount;
    this.url = url ?? '';
    // $_FORM_TYPE_ADJUSTMENT_$ -> Exclude specific form types from all stops
    this.redNavbar = !!this.url.match(/all_stop/) /* && this.userType !== 'user_type_to_exclude' */;
    this.printing = /\/forms\/print/i.test(this.url);

    this.title = this.url.match(/\/forms\/edit/i)
      ? this.title
      : this.url.match(/\/forms\/summary/i)
        ? PageNavigationTitle.MY_FORMS
        : this.url.match(/search/)
          ? PageNavigationTitle.SEARCH
          : this.url.match(/crew-favorites#edit-crew-favorite/)
            ? this.crewFavoritesTitle
            : this.url.match(/crew-favorites|\/forms\/print/)
              ? PageNavigationTitle.BACK
              : PageNavigationTitle.MAIN_TITLE;

    this.updateUserHistory();
  }

  /**
   * Updates the main title based on the current user type
   */
  setMainTitle() {
    // Titlecases the user type (applies to each segment if the user type is formatted with a hyphen like 'non-craft')
    const userTypeDisplay = this.userType.split('-').map(segment => `${segment.charAt(0).toUpperCase()}${segment.slice(1, segment.length)}`).join('-');
    const environments = this.config.getConfig('envName').toUpperCase()
    this.showEnvironments = environments !== 'PROD'
    if (this.showEnvironments) {
      PageNavigationTitle.MAIN_TITLE = `${userTypeDisplay} Job Brief (${environments})`;
    } else {
      PageNavigationTitle.MAIN_TITLE = `${userTypeDisplay} Job Brief`;
    }
  }

  /**
   * Returns the current session's history as a comma-delimited list
   */
  getUserHistory() {
    return sessionStorage.getItem('userHistory') ?? '';
  }
  /**
   * Updates the current session's history with the current url
   */
  updateUserHistory() {
    if (!this.userHasVisitedPage(location.href)) {
      sessionStorage.setItem('userHistory', `${this.getUserHistory()},${location.href}`);
    }
  }

  /**
   * Whether or not a user has visited a specific url during this session
   *
   * @param page Url
   */
  userHasVisitedPage(page: string) {
    return this.getUserHistory().split(',').some(h => h === page);
  }

  get title() {
    return this.viewingFormFromSearch
      ? 'Forms'
      : this._title;
  }

  set title(val: string) {
    val = val ?? PageNavigationTitle.MAIN_TITLE;
    this.showBack = val !== PageNavigationTitle.MAIN_TITLE;
    this._title = val;
  }

  goBack() {
    const review = this.activatedRoute?.snapshot?.queryParams['review'] === 'true' ? true : false;
    if (/\/forms\/(edit|summary)\/.{8}-.{4}-.{4}-.{4}-.{12}\/.{8}-.{4}-.{4}-.{4}-.{12}#.+$/.test(location.href) || review) {
      if (!this.userHasVisitedPage(location.href.split('#')[0]) || review) {
        // console.log(`User hasn't visited ${location.href.split('#')[0]}`);
        // /forms/edit/acb75f0e-79b8-4558-8964-4008e6f2f6cc/997d7edf-1045-44ed-b332-b1112860f00f#job_info_and_description
        // -> /forms/edit/acb75f0e-79b8-4558-8964-4008e6f2f6cc/997d7edf-1045-44ed-b332-b1112860f00f
        // Change location in callback so form has a chance to save locally
        if(review) {
          const newUrl = location.href.split('?')[0].replace('edit', 'final-review');
          this._back$.next(() => location.replace(newUrl));
        } else this._back$.next(() => location.replace(location.href.split('#')[0]));
      } else {
        this.navigateBack();
      }
    } else if (/\/search\/results.+$/.test(location.href)) {
      if (!this.userHasVisitedPage(`${this.config.getConfig('app_url')}search`)) {
        console.log(`User hasn't visited ${this.config.getConfig('app_url')}search`)
        // /search/results?opCenter=Anderson&searchValue=test
        // -> /search?opCenter=Anderson&searchValue=test
        location.replace(location.href.split('/results').join(''));
      } else {
        this.navigateBack();
      }
    } else if (!this.userHasVisitedPage(`${this.config.getConfig('app_url')}forms`)) {
      console.log(`User hasn't visited ${this.config.getConfig('app_url')}forms`)
      // /forms/edit/acb75f0e-79b8-4558-8964-4008e6f2f6cc/997d7edf-1045-44ed-b332-b1112860f00f
      // /forms/edit/acb75f0e-79b8-4558-8964-4008e6f2f6cc
      // /crew-favorites
      // -> /forms
      this.replaceLocationWithMyFormsPage();
    } else {
      if(location.href.includes('final-review')) {
        const newUrl = location.href?.replace('final-review', 'edit')?.split('/');
        this.router.navigate(['/forms', newUrl[4], newUrl[5], newUrl[6]]);
      } else if(location.href.includes('edit') && !location.href.includes('#')) {
        this.router.navigate(['/forms']);
      } 
      else this.navigateBack();
    }
  }

  /**
   * Calls the history.back() method if we are able to in the current user context
   */
  private navigateBack() {
    if (
      // Don't navigate back if on main /forms page
      !/\/forms$/.test(location.href)
      // Don't navigate back without confirming if on unsaved crew favorite
      && !((this.title === PageNavigationTitle.CREW_FAVORITES || this.title === PageNavigationTitle.TECH_FAVORITES)
        && !confirm('Are you sure you wish to go back and lose any changes?'))) {
      const prevUrl = location.href;
      // console.log(`Leaving ${location.href}`)

      if (location.href.includes('participant_list')) {
        this.expanded$.next(false);
      }

      history.back();
      setTimeout(() => {
        // console.log(`Navigated to ${location.href}`)
        // Navigate back again if the previous url is the same (unless we're on the /forms main page)
        // OR if the url is /forms/edit in a scenario other than the initial form creation screen
        // These cases occur as a result of duplicate history entries in some form scenarios
        const isOnFormsPage = /\/forms$/.test(location.href);
        const isPrevPageFormCreate = /\/forms\/edit#(job_info_and_description|job_information_and_scope|emergency_info)$/.test(prevUrl);
        const isPrevPageFormPrint = /\/forms\/print/.test(prevUrl);
        const isOnEditPage = /\/forms\/edit$/.test(location.href);
        if ((location.href === prevUrl && !isOnFormsPage && !isPrevPageFormPrint) || (!isPrevPageFormCreate && isOnEditPage)) {
          history.back();
        }
      }, 50);
    }
  }

  goHome() {
    location.replace('');
  }

  replaceLocationWithMyFormsPage() {
    location.replace(`${this.config.getConfig('app_url')}forms`);
  }

  /**
   * Removes the hash fragment from the current history state without any navigation
   */
  clearHashFragmentWithoutNavigation(goToUrl?: string) {
    this._hashFragment$.next(undefined);
    history.replaceState('', '', `${location.href.split('#')[0]}${goToUrl?.length > 0 ? `/${goToUrl}` : ''}`);
  }

  openHamburger() {
    const hamburgerRef = this.overlayService.open(AppDrawerComponent, {
      width: '300px',
      height: '100vh',
      left: true,
      top: true,
      data: {
        userType: this.userType
      }
    });
    hamburgerRef.afterClosed().subscribe(() => {
      // Subscription runs after the dialog closes
      // console.log('Dialog closed!');
    });
  }

  back$() {
    return this._back$;
  }

  hashFragment$() {
    return this._hashFragment$;
  }
}
